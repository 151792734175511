import React from 'react';
import Image from 'next/image';
import { Field, Form } from 'formik';
import ErrorIcon from '@mui/icons-material/Error';
import { Alert, DialogContent, Grid, Grow } from '@mui/material';
import { useTranslation } from 'i18n';

import useUppercaseTextWarning from 'hooks/useUppercaseTextWarning';

import InputTitle from 'components/common/InputTitle';
import { FieldToInputBase } from 'components/common/FormField';
import StickyBottomContent from 'components/common/StickyBottomContent';
import Button from 'components/common/Button/Button';
import withCreateEventForm, { FormComponentProps } from './withCreateEventForm';

const CreateEventModalForm: React.FC<React.PropsWithChildren<FormComponentProps>> = (props) => {
  const { disabled, values, onClose } = props;
  const imageSrc = '/static/svg/create-event.svg';
  const [t] = useTranslation('admin');

  const warning = useUppercaseTextWarning(values.title);

  return (
    <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <DialogContent>
        <Grid container spacing={5} justifyContent="center">
          <Grid item>
            <Image src={imageSrc} alt={imageSrc} width={141} height={143} layout="intrinsic" />
          </Grid>
          <Grid item xs={12}>
            <Field
              placeholder
              fullWidth
              name="title"
              namespace="event"
              maxLength={100}
              component={FieldToInputBase}
              InputBase={InputTitle}
              disabled={disabled}
            />
          </Grid>
          {warning && (
            <div>
              <Grow in={warning}>
                <Grid item xs={12}>
                  <Alert icon={<ErrorIcon />} severity="warning">
                    {t<string>('creatingAdminEvent.eventDetailsForm.description.warning')}
                  </Alert>
                </Grid>
              </Grow>
            </div>
          )}
        </Grid>
      </DialogContent>
      <StickyBottomContent position="sticky">
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button rounded variant="outlined" color="secondary" disabled={disabled} onClick={onClose}>
              {t<string>('common:button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button rounded variant="contained" color="secondary" type="submit" disabled={disabled}>
              {t<string>('common:button.create')}
            </Button>
          </Grid>
        </Grid>
      </StickyBottomContent>
    </Form>
  );
};

export default withCreateEventForm(CreateEventModalForm);
