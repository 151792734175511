import React, { useCallback, useEffect } from 'react';

import { Dialog, Theme, useMediaQuery } from '@mui/material';

import CustomDialogTitle from 'components/common/CustomDialogTitle';

import { Trans, useTranslation } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import AddOrganisationModal from './CreateEventPlaceholderModal';
import { EventDraftActions } from '../../../store/actions';
import { EventDraftSelectors } from '../../../store/selectors';
import { routes } from '../../../utils/routing';
import { useHashParams } from '../../../hooks';
import { RootState } from '../../../store/rootReducer';

interface IProps {
  onClose: () => void;
  open: boolean;
  exclude_from_calendar_before_event?: number;
}

const useStyles = makeStyles()(() => ({
  header: {
    paddingLeft: 64,
  },
  explanationContainer: {
    paddingTop: 8,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '160%',
    color: '#7B8BAD',
  },

  boldExplanationText: {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '160%',
    color: '#7B8BAD',
  },
}));

const CreateEventPlaceholderModalContainer: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { onClose, exclude_from_calendar_before_event, ...rest } = props;
  const [t] = useTranslation('admin');
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const [hashParams, setHashParams] = useHashParams();

  const createEventDraftState = useSelector((state: RootState) => EventDraftSelectors.createEventDraft(state));
  const { result, isPending, isFulfilled } = createEventDraftState;
  const organizationId = Number(router.query.organizationId);

  useEffect(() => {
    if (isFulfilled) {
      dispatch(EventDraftActions.createEvent.reset());
    }
    if (result && isFulfilled) {
      const route = routes.adminOrganizationEvents(
        organizationId,
        {
          status_display: 'scheduled',
        },
        {
          draftId: result.id,
          marketId: result.market.id,
          marketSlug: result.market.slug,
        },
      );
      if (router.pathname === route.link.split('?')[0]) {
        setHashParams({
          ...hashParams,
          draftId: result.id,
          marketId: result.market.id,
          marketSlug: result.market.slug,
        });
      } else {
        router.push(route.href);
      }
      onClose();
    }
  }, [dispatch, result, isFulfilled, onClose, organizationId, router, setHashParams, hashParams]);

  const handleSubmit = useCallback(
    (values: any) => {
      setHashParams({});
      dispatch(
        EventDraftActions.createEvent.request({
          params: { organizationId, entity: { ...values, sync_to_community_calendar_enabled: true } },
        }),
      );
    },
    [dispatch, organizationId, setHashParams],
  );

  const handleClose = () => {
    dispatch(EventDraftActions.createEvent.reset());
    onClose();
  };

  return (
    <Dialog maxWidth="sm" fullScreen={isMobile} {...rest}>
      <CustomDialogTitle className={classes.header} onClose={handleClose}>
        {t<string>('creatingAdminEventPlaceholder.modalTitle')}
        <div className={classes.explanationContainer}>
          <Trans
            i18nKey="creatingAdminEventPlaceholder.modalDescription"
            ns="admin"
            days={exclude_from_calendar_before_event || 0}
          >
            This will create a<span className={classes.boldExplanationText}> Draft Event </span>
            that, once approved, will appear on the Community Calendar
            {/* TODO: */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <span className={classes.boldExplanationText}>
              {' '}
              until {{ days: exclude_from_calendar_before_event || 0 }} days prior to event start.
            </span>
          </Trans>
        </div>
      </CustomDialogTitle>

      <AddOrganisationModal onClose={onClose} onSubmit={handleSubmit} disabled={isPending || isFulfilled} />
    </Dialog>
  );
};

export default CreateEventPlaceholderModalContainer;
