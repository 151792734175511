import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'i18n';
import { useRouter } from 'next/router';
import { routes } from 'utils/routing';
import { makeEntitiesLoader } from 'hooks/makeSimpleLoader';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { isFetchedStates, isFetchingStates } from 'store/utils';

import { AdminOrganizationsActions, MarketsActions } from 'store/actions';
import { AdminOrganizationsSelectors, MarketsSelectors } from 'store/selectors';

import AdminOrganizationsSelect from './AdminOrganizationsSelect';

const useMarketsState = makeEntitiesLoader(MarketsActions.getMarkets, MarketsSelectors.marketsState, false);
const params = {
  page_size: 100,
};

const useAdminOrganizationsState = makeEntitiesLoader(
  AdminOrganizationsActions.getAdminAllOrganizations,
  AdminOrganizationsSelectors.adminAllOrganizationsState,
  false,
);

const AdminOrganizationsSelectContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [t] = useTranslation('admin');
  const router = useRouter();
  const organizationId = Number(router.query.organizationId);
  const queryMarketSlug = String(router.query.marketSlug);

  const marketsState = useMarketsState(() => params, []);
  const allOrganizationState = useAdminOrganizationsState();

  const marketFromQuery = useSelector((state: RootState) => MarketsSelectors.marketBySlug(state, queryMarketSlug));
  const chosenOrganization = useSelector((state: RootState) =>
    AdminOrganizationsSelectors.allOrganizationsById(state, organizationId),
  );
  const marketFromOrg = useSelector((state: RootState) =>
    MarketsSelectors.marketById(state, Number(chosenOrganization?.market_id)),
  );
  const [market, setMarket] = useState('');

  const marketFromSelect = useSelector((state: RootState) => MarketsSelectors.marketById(state, Number(market)));
  const filteredMarkets = marketsState.entities.filter((market) =>
    allOrganizationState.entities.some((org) => Number(org.market_id) === Number(market.id)),
  );
  const filteredOrganizations = market
    ? allOrganizationState.entities.filter((org) => Number(org.market_id) === Number(market))
    : allOrganizationState.entities;

  const handleOrganizationSelect = (id: number) => {
    let route;
    if (id) {
      const marketFromId = allOrganizationState.entities.filter((item) => item.id === id);
      if (marketFromId.length > 0) {
        setMarket(String(marketFromId[0].market_id));
      }
      route = routes.adminOrganizationEvents(id);
    } else if (market) {
      route = routes.adminMarketEvents(marketFromSelect.slug);
    } else {
      route = routes.adminAllEvents();
    }
    router.push(route.href);
  };

  useEffect(() => {
    if (isFetchedStates(marketsState, allOrganizationState) && (marketFromQuery?.id || marketFromOrg?.id)) {
      setMarket(String(marketFromQuery?.id || marketFromOrg?.id));
    }
  }, [allOrganizationState, marketFromOrg?.id, marketFromQuery, marketsState]);

  return (
    <AdminOrganizationsSelect
      t={t}
      isFetching={isFetchingStates(marketsState, allOrganizationState)}
      organizationId={organizationId}
      onSelect={handleOrganizationSelect}
      options={filteredOrganizations}
      chosenOrganization={chosenOrganization}
      markets={filteredMarkets}
      market={market}
      onFilterChange={setMarket}
    />
  );
};

export default memo(AdminOrganizationsSelectContainer);
