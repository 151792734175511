import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { inputISOStringToDate, outputDateToISOString } from 'utils/transform';
import { formatDate } from 'utils/format';
import { SxProps } from '@mui/system';
import DateIconButton from './DateIconButton';
import withDatePicker from './withDatePicker';

interface IProps {
  autoOk?: boolean;
  disabled?: boolean;
  timeZone?: string;
  value?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  open: boolean;
  fullWidth?: boolean;
  onBlur?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  onChange?: (v: string) => void;
  InputComponent?: React.ElementType;
}

const DatePicker: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const {
    label,
    placeholder,
    value,
    timeZone = 'UTC',
    onOpen,
    onChange,
    open,
    onClose,
    error,
    helperText,
    fullWidth,
    InputComponent = DateIconButton,
    ...rest
  } = props;

  const handleChange = (v: any) => {
    onChange(outputDateToISOString(v?.toDate(), timeZone));
  };

  const popperSx: SxProps = {
    '& .MuiPickersCalendarHeader-root, .MuiSvgIcon-root': {
      color: '#4E6DF5',
    },
    '& .MuiPickersCalendarHeader-label': {
      fontSize: 16,
      fontWeight: 600,
    },
    '& .MuiPickersDay-root': {
      fontSize: 15,
      fontWeight: 600,
      color: '#383C44',
      borderRadius: '6px',
      ':first-child': {
        color: '#F65675',
      },
    },
    '& .Mui-selected': {
      color: '#fff !important',
      backgroundColor: '#4E6DF5',
    },
    '& .MuiYearPicker-root': {
      maxHeight: 280,
    },
  };

  return (
    <MuiDatePicker
      PopperProps={{
        sx: popperSx,
      }}
      {...rest}
      open={open}
      value={inputISOStringToDate(value, timeZone)}
      onChange={handleChange}
      onClose={onClose}
      renderInput={(props) => {
        const { inputRef, ...rest } = props;
        return (
          <InputComponent
            {...rest}
            ref={inputRef}
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            error={error}
            value={value ? formatDate(value, 'MM/dd/yyyy', timeZone) : ''}
            onClick={onOpen}
            onOpen={onOpen}
          />
        );
      }}
    />
  );
};

export default withDatePicker(DatePicker);
